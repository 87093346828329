<template>
    <div class="plants-grow" @touchend="verifyDistance()">
        <div class="nav" v-show="isShow">
            <div class="top-title">
                <div class="top-title-iocn" @click="goBack"></div>
                <div class="top-title-content">{{ goodsInfo.processName }}</div>
            </div>
        </div>
        <div class="nav-less" v-show="!isShow">
            <div class="top-title">
                <div class="top-title-iocn" @click="goBack"></div>
                <img :src="goodsInfo.processImg" />
                <div class="mask">
                    <p class="title">{{goodsInfo.processName}}</p>
                    <p class="time">{{ goodsInfo.processDate }}</p>
                </div>
            </div>
        </div>
        <div class="content" id="content">
            <div class="tree-detail">
                <ul>
                    <li v-for="(item,index) in goodsInfo.processList" :key="index">
                        <div class="left">
                            <div class="icon">
                                <div class="dot"></div>
                            </div>
                            <div class="line" v-if="(index + 1) != goodsInfo.processList.length"></div>
                        </div>
                        <div class="right">
                            <div class="period">{{item.processName}}</div>
                            <div class="date">{{item.startTimeStr}}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { getProcessInfo } from '@/api/suyuan'
    export default {
        data() {
            return {
                goodsInfo:{
                    processName:'',
                    processImg:'',
                    processDate:'',
                    processList:[]
                },
                processId:'',
                isShow: false, //判断是否显示顶部导航栏
                batchsId:''
            };
        },
        mounted() {
            //获取路由跳转的过程id
            this.processId = this.$route.params.hasOwnProperty('processId') ? this.$route.params.processId : '';
            //获取路由跳转的批次id
            this.batchsId = this.$route.params.hasOwnProperty('batchsCode') ? this.$route.params.batchsCode : '';
            //获取过程信息
            this.getProcessInfo();
        },
        methods: {
            /**
             * 导航返回事件
             */
            goBack(){
                this.$router.go(-1)
            },
            /**
             * 获取过程信息
             */
            getProcessInfo(){
                getProcessInfo({batchsId:this.batchsId,processId: this.processId}).then( res => {
                    let data = res.data;
                    this.goodsInfo.processName = data.processName;
                    this.goodsInfo.processDate = data.startTimeStr;
                    this.goodsInfo.processImg = data.imgUrl;
                    this.goodsInfo.processList = data.processList;
                })
            },
            /**
             *判断内容与顶部的距离
             */
            verifyDistance() {
                let height = document.documentElement.scrollTop;
                if (height >= 65) {
                    this.isShow = true;
                } else {
                    this.isShow = false;
                }
            }
        },
        destroyed() {
            window.removeEventListener('scroll', this.verifyDistance);
        }
    }
</script>
<style lang="scss" scoped>
    .plants-grow {
        width: 100%;
        position: relative;

        .nav-less {
            position: relative;
            width: 100%;
            height: 180px;
            background-color: #fff;

            .top-title-iocn {
                position: absolute;
                top: 14px;
                left: 14px;
                width: 16px;
                height: 16px;
                background: url('../../../assets/planB/back-w.png') no-repeat top center;
                background-size: cover;
                z-index: 2;
            }

            img {
                width: 100%;
                height: 180px;
            }

            .mask {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                background: rgba(0, 0, 0, 0.1);
                z-index: 0;
                .title {
                    position: absolute;
                    bottom: 33px;
                    left: 16px;
                    font-size: 20px;
                    color: #fff;
                }

                .time {
                    position: absolute;
                    bottom: 12px;
                    left: 16px;
                    font-size: 14px;
                    color: rgba(255, 255, 255, .8);
                }
            }
        }

        .nav {
            width: 100%;
            height: 64px;
            background-color: #fff;

            .top-title {
                position: fixed;
                display: flex;
                align-items: center;
                width: 100%;
                height: 64px;
                box-sizing: border-box;
                padding-top: 22px;
                padding-left: 15px;
                background-color: #fff;
                z-index: 99;

                .top-title-iocn {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background: url('../../../assets/planC/back-w.png') no-repeat top center;
                    background-size: cover;
                    z-index: 2;
                }

                .top-title-content {
                    position: absolute;
                    left: calc(50% - 16px);
                    transform: translate(-50%);
                    text-align: center;
                    font-size: 20px;
                    color: #000;
                    z-index: 1;
                }
            }
        }

        .content {
            padding: 0 15px;
            width: 100%;
            box-sizing: border-box;

            .tree-detail {
                width: 100%;
                padding: 12px 0;

                ul {
                    width: 100%;

                    li {
                        display: flex;
                        height: 74px;
                        position: relative;

                        .left {
                            display: flex;
                            flex-direction: column;
                            position: absolute;
                            top: 6px;

                            .icon {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 10px;
                                height: 10px;
                                box-sizing: border-box;
                                border: 1px solid #4BCED0;
                                border-radius: 50%;

                                .dot {
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background-color: #4BCED0;
                                }
                            }

                            .line {
                                height: 62px;
                                width: 1px;
                                border-left: 1px dotted #4BCED0;
                                margin-left: 5px;
                            }
                        }

                        .right {
                            width: 100%;
                            line-height: 24px;
                            font-size: 14px;
                            color: #8C8C8C;
                            box-sizing: border-box;
                            padding-left: 22px;

                            .period {
                                flex: 1;
                                height: 22px;
                                line-height: 22px;
                                font-size: 16px;
                                color: #262626;
                            }
                        }
                    }
                }
            }
        }
    }
</style>